.Unbutton {
  padding: 0;
  display: inline;
  border: none;
  background: transparent;
  border-radius: 0;
  color: inherit; }

.Unbutton:hover {
  cursor: pointer; }

.Unbutton:focus {
  outline: none; }

.Unbutton--link {
  color: #047bb7; }

.Unbutton--link:hover {
  color: #036a9e; }

.Unbutton--link:focus {
  color: #036a9e; }

.Unbutton--editable {
  border-bottom: 0.0625rem dashed #047bb7; }
