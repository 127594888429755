.SubmitField {
  margin-bottom: 1.25rem; }

.SubmitField__label--required {
  font-weight: bold; }

.SubmitFiled__group {
  display: flex; }

.SubmitFiled__input {
  flex-grow: 1;
  flex-basis: 0; }

.SubmitFiled__description {
  color: #a2a4a6;
  font-size: 0.8125rem; }

.SubmitFiled__error {
  color: #d9534f; }
