.Overlay__backdrop {
  display: flex;
  overflow-y: scroll;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #d3e0e9; }

.Overlay__content {
  margin: auto; }

.Overlay__close {
  z-index: 1001;
  position: fixed;
  top: 1.25rem;
  right: 1.25rem;
  padding: 0;
  display: inline;
  border: none;
  background: transparent;
  border-radius: 0;
  color: #233844;
  text-align: center; }

.Overlay__close:hover {
  cursor: pointer;
  color: #d9534f; }

.Overlay__close:focus {
  outline: none; }

.Overlay__close svg {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem; }

.Overlay__close span {
  display: block;
  font-size: 1rem; }
