.Table {
  margin-bottom: 1.25rem;
  text-align: left; }
  .Table th,
  .Table td {
    padding: 0 1.25rem 1.25rem 0; }

.Table--bordered th,
.Table--bordered td {
  border: 0.0625rem solid #d3e0e9;
  padding: 0.625rem 1.25rem; }

.Table--condensed th,
.Table--condensed td {
  padding: 0 0.625rem 0.625rem 0; }

.Table--condensed.Table--bordered th,
.Table--condensed.Table--bordered td {
  border: 0.0625rem solid #d3e0e9;
  padding: 0.3125rem 0.625rem; }
