.BlogPostLayout__layout-main {
  width: 50rem;
  margin: 0 auto; }

.BlogPostLayout__meta {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem; }

.BlogPostLayout__meta-author {
  flex: 1 1 0; }

.BlogPostLayout__post {
  margin-bottom: 2.5rem;
  font-size: 1.2em;
  text-align: justify; }

.BlogPostLayout__comments {
  margin-bottom: 2.5rem; }
