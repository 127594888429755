.sticky-inner-wrapper {
  z-index: 2; }

.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.0625rem;
  margin: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.0625rem; }

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s; }

.react-toggle-track {
  width: 3.125rem;
  height: 1.5rem;
  padding: 0;
  border-radius: 1.875rem;
  background-color: #d3e0e9;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #c2d4e1; }

.react-toggle--checked .react-toggle-track {
  background-color: #5cb85c; }

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #4cae4c; }

.react-toggle-track-check {
  position: absolute;
  width: 0.875rem;
  height: 0.625rem;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 0.5rem;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; }

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; }

.react-toggle-track-x {
  position: absolute;
  width: 0.625rem;
  height: 0.625rem;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 0.625rem;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; }

.react-toggle--checked .react-toggle-track-x {
  opacity: 0; }

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 0.0625rem;
  left: 0.0625rem;
  width: 1.375rem;
  height: 1.375rem;
  border: 0.0625rem solid #a0bcd0;
  border-radius: 50%;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease; }

.react-toggle--checked .react-toggle-thumb {
  left: 1.6875rem; }

/*
 * react-circular-progressbar styles
 *
 * All of the styles in this file are optional and configurable!
 */
.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   *
   * If you're not using "display: flex", you can remove this style.
   */
  display: block;
  width: 100%; }

.CircularProgressbar-path {
  stroke: #047bb7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s; }
  .CircularProgressbar--complete .CircularProgressbar-path {
    stroke: #5cb85c; }

.CircularProgressbar-trail {
  stroke: #d3e0e9; }

.CircularProgressbar-text {
  fill: #047bb7;
  font-size: 30px;
  dominant-baseline: middle;
  text-anchor: middle; }
  .CircularProgressbar--complete .CircularProgressbar-text {
    fill: #5cb85c; }

.CircularProgressbar-background {
  fill: #d3e0e9; }

.ReactVirtualized__Grid:focus {
  outline: none; }
