.DefaultLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 75rem; }

.DefaultLayout__main {
  flex-grow: 1;
  margin: 0 auto;
  padding: 2.5rem 1.25rem;
  width: 100%;
  min-width: 75rem;
  max-width: 75rem; }
