.Input {
  display: block;
  padding: 0.625rem 0.625rem;
  background-color: #ffffff;
  border: 0.0625rem solid #d3e0e9;
  border-radius: 0.1875rem; }

.Input[type='checkbox'] {
  display: inline-block;
  width: auto;
  padding: 0;
  margin-right: 0.5rem;
  position: relative;
  top: -0.0625rem; }

.Input:focus {
  border-color: #047bb7;
  outline: none; }

.Input[disabled] {
  background-color: #f8fafb;
  color: #a2a4a6; }

.Input--error {
  border-color: #d9534f; }

.Input--squareRight {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }
