@keyframes rotate {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }

.Button {
  display: inline-block;
  text-align: left;
  position: relative;
  padding: 0;
  cursor: pointer;
  border-radius: 0.1875rem;
  border: 0.125rem solid #d3e0e9;
  text-decoration: none;
  line-height: 1.15; }

.Button--squareLeft {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.Button + .Button {
  margin-left: 0.625rem; }

.Button + .FileButton {
  margin-left: 0.625rem; }

.Button:focus {
  outline: none; }

.Button__content {
  font-size: 0.875rem;
  text-transform: uppercase;
  white-space: nowrap;
  display: block;
  padding: 0.75rem 1.125rem; }

.Button--block {
  display: block;
  width: 100%;
  text-align: center; }

.Button--tiny .Button__content {
  text-transform: none;
  font-size: 0.8125rem;
  padding: 0.19886rem 0.4375rem; }

.Button--tiny.Button--round {
  border-radius: 0.9375rem; }

.Button--small .Button__content {
  font-size: 0.8125rem;
  padding: 0.46429rem 0.8125rem; }

.Button--small.Button--round {
  border-radius: 0.9375rem; }

.Button--large .Button__content {
  font-size: 1rem;
  padding: 1rem 1.5rem; }

.Button--busy {
  cursor: wait; }

.Button--busy:before {
  width: 1.125rem;
  height: 1.125rem;
  position: absolute;
  left: calc(50% - 0.5625rem);
  top: calc(50% - 0.5625rem);
  content: '';
  border-radius: 50%;
  background-clip: padding-box;
  animation: rotate 750ms linear infinite; }

.Button--busy .Button__content {
  color: transparent; }

.Button--primary {
  border-color: #047bb7;
  background-color: #047bb7;
  color: #ffffff; }

.Button--primary:hover {
  border-color: #058cd0;
  background-color: #058cd0;
  color: #ffffff; }

.Button--primary:active,
.Button--primary.Button--busy,
.Button--primary.Button--busy:hover {
  border-color: #036a9e;
  background-color: #036a9e; }

.Button--primary.Button--busy:before {
  border: 0.125rem solid rgba(255, 255, 255, 0.25);
  border-top-color: #ffffff; }

.Button--secondary {
  border-color: #d3e0e9;
  background-color: #d3e0e9;
  color: #323333; }

.Button--secondary:hover {
  border-color: #e4ecf2;
  background-color: #e4ecf2;
  color: #323333; }

.Button--secondary:active,
.Button--secondary.Button--busy,
.Button--secondary.Button--busy:hover {
  border-color: #c2d4e1;
  background-color: #c2d4e1; }

.Button--secondary.Button--busy:before {
  border: 0.125rem solid rgba(50, 51, 51, 0.25);
  border-top-color: #323333; }

.Button--accent {
  border-color: #ee684d;
  background-color: #ee684d;
  color: #ffffff; }

.Button--accent:hover {
  border-color: #f07c64;
  background-color: #f07c64;
  color: #ffffff; }

.Button--accent:active,
.Button--accent.Button--busy,
.Button--accent.Button--busy:hover {
  border-color: #ec5436;
  background-color: #ec5436; }

.Button--accent.Button--busy:before {
  border: 0.125rem solid rgba(255, 255, 255, 0.25);
  border-top-color: #ffffff; }

.Button--red {
  border-color: #d9534f;
  background-color: #d9534f;
  color: #ffffff; }

.Button--red:hover {
  border-color: #de6764;
  background-color: #de6764; }

.Button--red:active,
.Button--red.Button--busy,
.Button--red.Button--busy:hover {
  border-color: #d43f3a;
  background-color: #d43f3a; }

.Button--red.Button--busy:before {
  border: 0.125rem solid rgba(255, 255, 255, 0.25);
  border-top-color: #ffffff; }

.Button--whiteOutline {
  border-color: #ffffff;
  background-color: transparent;
  color: #ffffff; }

.Button--whiteOutline:hover {
  background-color: rgba(255, 255, 255, 0.1); }

.Button--whiteOutline:active,
.Button--whiteOutline.Button--busy,
.Button--whiteOutline.Button--busy:hover {
  background-color: rgba(255, 255, 255, 0.1); }

.Button--whiteOutline.Button--busy:before {
  border: 0.125rem solid rgba(255, 255, 255, 0.25);
  border-top-color: #ffffff; }

.Button--primaryOutline {
  border-color: #047bb7;
  background-color: transparent;
  color: #047bb7; }

.Button--primaryOutline:hover {
  background-color: #047bb7;
  color: #ffffff; }

.Button--primaryOutline:active,
.Button--primaryOutline.Button--busy,
.Button--primaryOutline.Button--busy:hover {
  background-color: #047bb7;
  color: #ffffff; }

.Button--primaryOutline.Button--busy:before {
  border: 0.125rem solid rgba(4, 123, 183, 0.25);
  border-top-color: #047bb7; }

.Button--accentOutline {
  border-color: #ee684d;
  background-color: transparent;
  color: #ee684d; }

.Button--accentOutline:hover {
  background-color: #ee684d;
  color: #ffffff; }

.Button--accentOutline:active,
.Button--accentOutline.Button--busy,
.Button--accentOutline.Button--busy:hover {
  background-color: #ee684d;
  color: #ffffff; }

.Button--accentOutline.Button--busy:before {
  border: 0.125rem solid rgba(238, 104, 77, 0.25);
  border-top-color: #ee684d; }

.Button--greenOutline {
  border-color: #5cb85c;
  background-color: transparent;
  color: #5cb85c; }

.Button--greenOutline:hover {
  background-color: #5cb85c;
  color: #ffffff; }

.Button--greenOutline:active,
.Button--greenOutline.Button--busy,
.Button--greenOutline.Button--busy:hover {
  background-color: #5cb85c;
  color: #ffffff; }

.Button--greenOutline.Button--busy:before {
  border: 0.125rem solid rgba(92, 184, 92, 0.25);
  border-top-color: #5cb85c; }

.Button--redOutline {
  border-color: #d9534f;
  background-color: transparent;
  color: #d9534f; }

.Button--redOutline:hover {
  background-color: #d9534f;
  color: #ffffff; }

.Button--redOutline:active,
.Button--redOutline.Button--busy,
.Button--redOutline.Button--busy:hover {
  background-color: #d9534f;
  color: #ffffff; }

.Button--redOutline.Button--busy:before {
  border: 0.125rem solid rgba(217, 83, 79, 0.25);
  border-top-color: #d9534f; }

.Button--disabledOutline {
  border-color: #bcbebf;
  background-color: transparent;
  color: #bcbebf; }

.Button--disabledOutline:hover {
  cursor: not-allowed;
  border-color: #bcbebf;
  background-color: transparent;
  color: #bcbebf; }
