.ais-Breadcrumb-list,
.ais-CurrentRefinements-list,
.ais-HierarchicalMenu-list,
.ais-Hits-list,
.ais-Results-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-Pagination-list,
.ais-RatingMenu-list,
.ais-RefinementList-list,
.ais-ToggleRefinement-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.ais-ClearRefinements-button,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RangeInput-submit,
.ais-RefinementList-showMore,
.ais-SearchBox-submit,
.ais-SearchBox-reset {
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ais-ClearRefinements-button::-moz-focus-inner,
.ais-CurrentRefinements-delete::-moz-focus-inner,
.ais-CurrentRefinements-reset::-moz-focus-inner,
.ais-HierarchicalMenu-showMore::-moz-focus-inner,
.ais-InfiniteHits-loadMore::-moz-focus-inner,
.ais-InfiniteResults-loadMore::-moz-focus-inner,
.ais-Menu-showMore::-moz-focus-inner,
.ais-RangeInput-submit::-moz-focus-inner,
.ais-RefinementList-showMore::-moz-focus-inner,
.ais-SearchBox-submit::-moz-focus-inner,
.ais-SearchBox-reset::-moz-focus-inner {
  padding: 0;
  border: 0; }

.ais-ClearRefinements-button[disabled],
.ais-CurrentRefinements-delete[disabled],
.ais-CurrentRefinements-reset[disabled],
.ais-HierarchicalMenu-showMore[disabled],
.ais-InfiniteHits-loadMore[disabled],
.ais-InfiniteResults-loadMore[disabled],
.ais-Menu-showMore[disabled],
.ais-RangeInput-submit[disabled],
.ais-RefinementList-showMore[disabled],
.ais-SearchBox-submit[disabled],
.ais-SearchBox-reset[disabled] {
  cursor: default; }

.ais-Breadcrumb-list,
.ais-Breadcrumb-item,
.ais-Pagination-list,
.ais-RangeInput-form,
.ais-RatingMenu-link,
.ais-PoweredBy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1em; }

.ais-RatingMenu-starIcon {
  display: block;
  width: 20px;
  height: 20px; }

.ais-SearchBox-input::-ms-clear,
.ais-SearchBox-input::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  display: none; }

.ais-RangeSlider .rheostat {
  overflow: visible;
  margin-top: 40px;
  margin-bottom: 40px; }

.ais-RangeSlider .rheostat-background {
  height: 6px;
  top: 0px;
  width: 100%; }

.ais-RangeSlider .rheostat-handle {
  margin-left: -12px;
  top: -7px; }

.ais-RangeSlider .rheostat-background {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #aaa; }

.ais-RangeSlider .rheostat-progress {
  position: absolute;
  top: 1px;
  height: 4px;
  background-color: #333; }

.rheostat-handle {
  position: relative;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 50%;
  cursor: -webkit-grab;
  cursor: grab; }

.rheostat-marker {
  margin-left: -1px;
  position: absolute;
  width: 1px;
  height: 5px;
  background-color: #aaa; }

.rheostat-marker--large {
  height: 9px; }

.rheostat-value {
  margin-left: 50%;
  padding-top: 15px;
  position: absolute;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.rheostat-tooltip {
  margin-left: 50%;
  position: absolute;
  top: -22px;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

[class^='ais-'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

a[class^='ais-'] {
  text-decoration: none; }

.ais-Breadcrumb,
.ais-ClearRefinements,
.ais-CurrentRefinements,
.ais-HierarchicalMenu,
.ais-Hits,
.ais-Results,
.ais-HitsPerPage,
.ais-ResultsPerPage,
.ais-InfiniteHits,
.ais-InfiniteResults,
.ais-Menu,
.ais-MenuSelect,
.ais-NumericMenu,
.ais-NumericSelector,
.ais-Pagination,
.ais-Panel,
.ais-PoweredBy,
.ais-RangeInput,
.ais-RangeSlider,
.ais-RatingMenu,
.ais-RefinementList,
.ais-SearchBox,
.ais-SortBy,
.ais-Stats,
.ais-ToggleRefinement {
  color: #323333; }

.ais-Breadcrumb-item--selected,
.ais-HierarchicalMenu-item--selected,
.ais-Menu-item--selected {
  font-weight: bold; }

.ais-Breadcrumb-separator {
  margin: 0 0.3em;
  font-weight: normal; }

.ais-Breadcrumb-link,
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-Pagination-link,
.ais-RatingMenu-link {
  color: #047bb7;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out; }

.ais-Breadcrumb-link:hover,
.ais-Breadcrumb-link:focus,
.ais-HierarchicalMenu-link:hover,
.ais-HierarchicalMenu-link:focus,
.ais-Menu-link:hover,
.ais-Menu-link:focus,
.ais-Pagination-link:hover,
.ais-Pagination-link:focus,
.ais-RatingMenu-link:hover,
.ais-RatingMenu-link:focus {
  color: #035985; }

.ais-ClearRefinements-button,
.ais-CurrentRefinements-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
  color: #fff;
  background-color: #047bb7;
  border-radius: 5px;
  -webkit-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
  outline: none; }

.ais-ClearRefinements-button:hover,
.ais-ClearRefinements-button:focus,
.ais-CurrentRefinements-reset:hover,
.ais-CurrentRefinements-reset:focus,
.ais-HierarchicalMenu-showMore:hover,
.ais-HierarchicalMenu-showMore:focus,
.ais-InfiniteHits-loadMore:hover,
.ais-InfiniteHits-loadMore:focus,
.ais-InfiniteResults-loadMore:hover,
.ais-InfiniteResults-loadMore:focus,
.ais-Menu-showMore:hover,
.ais-Menu-showMore:focus,
.ais-RefinementList-showMore:hover,
.ais-RefinementList-showMore:focus {
  background-color: #035985; }

.ais-ClearRefinements-button--disabled,
.ais-HierarchicalMenu-showMore--disabled,
.ais-InfiniteHits-loadMore--disabled,
.ais-InfiniteResults-loadMore--disabled,
.ais-Menu-showMore--disabled,
.ais-RefinementList-showMore--disabled {
  opacity: 0.6;
  cursor: not-allowed; }

.ais-ClearRefinements-button--disabled:hover,
.ais-ClearRefinements-button--disabled:focus,
.ais-HierarchicalMenu-showMore--disabled:hover,
.ais-HierarchicalMenu-showMore--disabled:focus,
.ais-InfiniteHits-loadMore--disabled:hover,
.ais-InfiniteHits-loadMore--disabled:focus,
.ais-InfiniteResults-loadMore--disabled:hover,
.ais-InfiniteResults-loadMore--disabled:focus,
.ais-Menu-showMore--disabled:hover,
.ais-Menu-showMore--disabled:focus,
.ais-RefinementList-showMore--disabled:hover,
.ais-RefinementList-showMore--disabled:focus {
  background-color: #047bb7; }

.ais-CurrentRefinements {
  margin-top: -0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.ais-CurrentRefinements-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.ais-CurrentRefinements-item {
  margin-right: 0.3rem;
  margin-top: 0.3rem;
  padding: 0.46429rem 0.8125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fffce9;
  border: 0.0625rem solid #e0d8a2;
  border-radius: 1.25rem; }

.ais-CurrentRefinements-category {
  margin-left: 0.3em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .ais-CurrentRefinements-category + .ais-CurrentRefinements-category {
    margin-left: 0.625rem; }

.ais-CurrentRefinements-delete {
  margin-left: 0.3rem; }

.ais-CurrentRefinements-label,
.ais-CurrentRefinements-categoryLabel,
.ais-CurrentRefinements-delete {
  white-space: nowrap;
  font-size: 0.8rem;
  color: #323333; }

.ais-CurrentRefinements-delete {
  color: #d9534f; }
  .ais-CurrentRefinements-delete:hover {
    color: #c9302c; }

.ais-CurrentRefinements-reset {
  margin-top: 0.3rem;
  white-space: nowrap; }

.ais-CurrentRefinements-reset + .ais-CurrentRefinements-list {
  margin-left: 0.3rem; }

.ais-HierarchicalMenu-link,
.ais-Menu-link {
  display: block;
  line-height: 1.5; }

.ais-HierarchicalMenu-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-RatingMenu-list,
.ais-RefinementList-list {
  font-weight: normal;
  line-height: 1.5; }

.ais-HierarchicalMenu-link:after {
  margin-left: 0.3em;
  content: '';
  width: 10px;
  height: 10px;
  display: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z%27 fill%3D%22%233A4570%22 /%3E%3C/svg%3E");
  background-size: 100% 100%; }

.ais-HierarchicalMenu-item--parent > .ais-HierarchicalMenu-link:after {
  display: inline-block; }

.ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.ais-CurrentRefinements-count,
.ais-RatingMenu-count {
  font-size: 0.8rem; }

.ais-CurrentRefinements-count:before,
.ais-RatingMenu-count:before {
  content: '('; }

.ais-CurrentRefinements-count:after,
.ais-RatingMenu-count:after {
  content: ')'; }

.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RefinementList-count,
.ais-ToggleRefinement-count {
  padding: 0.1rem 0.4rem;
  font-size: 0.7rem;
  color: #323333;
  background-color: #d3e0e9;
  border-radius: 0.1875rem;
  margin-left: 0.125rem; }

.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  margin-top: 0.5rem; }

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  background-color: #ffc168;
  color: #323333; }

.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Hits-list,
.ais-Results-list {
  margin-bottom: 1.25rem; }

.ais-Panel-body .ais-InfiniteHits-list,
.ais-Panel-body .ais-InfiniteResults-list,
.ais-Panel-body .ais-Hits-list,
.ais-Panel-body .ais-Results-list {
  margin: 0.5rem 0 0 -1rem; }

.ais-Panel-body .ais-InfiniteHits-item,
.ais-Panel-body .ais-InfiniteResults-item,
.ais-Panel-body .ais-Hits-item,
.ais-Panel-body .ais-Results-item {
  margin: 0.5rem 0 0.5rem 1rem; }

.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore {
  margin-top: 1rem; }

.ais-MenuSelect-select,
.ais-NumericSelector-select,
.ais-HitsPerPage-select,
.ais-ResultsPerPage-select,
.ais-SortBy-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.3rem 2rem 0.3rem 0.3rem;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z%27 fill%3D%22%233A4570%22 /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: 92% 50%;
  border: 1px solid #c4c8d8;
  border-radius: 5px; }

.ais-Panel-header {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #c4c8d8; }

.ais-Panel-footer {
  margin-top: 0.5rem;
  font-size: 0.8rem; }

.ais-RangeInput-input {
  padding: 0 0.2rem;
  width: 5rem;
  height: 1.5rem;
  line-height: 1.5rem; }

.ais-RangeInput-separator {
  margin: 0 0.3rem; }

.ais-RangeInput-submit {
  margin-left: 0.3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 0.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.8rem;
  color: #fff;
  background-color: #047bb7;
  border: none;
  border-radius: 5px;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  outline: none; }

.ais-RangeInput-submit:hover,
.ais-RangeInput-submit:focus {
  background-color: #035985; }

.ais-RatingMenu-count {
  color: #323333; }

.ais-Pagination-list {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.ais-Pagination-item + .ais-Pagination-item {
  margin-left: -0.0625rem; }

.ais-Pagination-item:first-child .ais-Pagination-link {
  border-radius: 0.1875rem 0 0 0.1875rem; }

.ais-Pagination-item:last-child .ais-Pagination-link {
  border-radius: 0 0.1875rem 0.1875rem 0; }

.ais-Pagination-link {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  display: block;
  background-color: #f8fafb;
  border: 0.0625rem solid #d3e0e9; }

.ais-Pagination-link:hover,
.ais-Pagination-link:focus {
  background-color: #ffffff; }

.ais-Pagination-item--disabled .ais-Pagination-link {
  opacity: 0.6;
  cursor: not-allowed;
  color: #a5abc4; }

.ais-Pagination-item--disabled .ais-Pagination-link:hover,
.ais-Pagination-item--disabled .ais-Pagination-link:focus {
  color: #a5abc4;
  background-color: #fff; }

.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: #047bb7;
  border-color: #047bb7; }

.ais-Pagination-item--selected .ais-Pagination-link:hover,
.ais-Pagination-item--selected .ais-Pagination-link:focus {
  color: #fff; }

.ais-Stats {
  line-height: 1; }

.ais-PoweredBy-text,
.rheostat-tooltip,
.rheostat-value {
  font-size: 0.8125rem; }

.ais-Stats-text {
  font-size: 1rem; }

.ais-PoweredBy-logo {
  margin-left: 0.3rem; }

.ais-RangeSlider .rheostat-progress {
  background-color: #047bb7; }

.ais-RangeSlider .rheostat-background {
  border-color: #047bb7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.ais-RangeSlider .rheostat-handle {
  border-color: #878faf; }

.ais-RangeSlider .rheostat-marker {
  background-color: #878faf; }

.ais-Panel-body .ais-RangeSlider {
  margin: 2rem 0; }

.ais-RatingMenu-item--disabled .ais-RatingMenu-count,
.ais-RatingMenu-item--disabled .ais-RatingMenu-label {
  color: #c4c8d8; }

.ais-RatingMenu-item--selected {
  font-weight: bold; }

.ais-RatingMenu-link {
  line-height: 1.5; }

.ais-RatingMenu-link > * + * {
  margin-left: 0.3rem; }

.ais-RatingMenu-starIcon {
  position: relative;
  top: -1px;
  width: 15px;
  fill: #ffc168; }

.ais-RatingMenu-item--disabled .ais-RatingMenu-starIcon {
  fill: #c4c8d8; }

.ais-HierarchicalMenu-searchBox > *,
.ais-Menu-searchBox > *,
.ais-RefinementList-searchBox > * {
  margin-bottom: 0.5rem; }

.ais-SearchBox-form {
  display: block;
  position: relative;
  margin-bottom: 1.25rem; }

.ais-SearchBox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.625rem 2.1875rem;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border: 0.0625rem solid #d3e0e9;
  border-radius: 0.1875rem; }

.ais-SearchBox-input:focus {
  border-color: #047bb7;
  outline: none; }

.ais-SearchBox-input::-webkit-input-placeholder {
  color: #bcbebf; }

.ais-SearchBox-input::-moz-placeholder {
  color: white; }

.ais-SearchBox-input:-ms-input-placeholder {
  color: white; }

.ais-SearchBox-input:-moz-placeholder {
  color: white; }

.ais-SearchBox-submit,
.ais-SearchBox-reset,
.ais-SearchBox-loadingIndicator {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 1;
  width: 1.25rem;
  height: 1.25rem;
  top: 50%;
  right: 0.625rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.ais-SearchBox-submit {
  left: 0.625rem; }

.ais-SearchBox-reset {
  right: 0.625rem; }

.ais-SearchBox-submitIcon,
.ais-SearchBox-resetIcon,
.ais-SearchBox-loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%); }

.ais-SearchBox-submitIcon path,
.ais-SearchBox-resetIcon path {
  fill: #495588; }

.ais-SearchBox-submitIcon {
  width: 14px;
  height: 14px; }

.ais-SearchBox-resetIcon {
  width: 12px;
  height: 12px; }

.ais-SearchBox-loadingIcon {
  width: 16px;
  height: 16px; }

.ais-RefinementList-item + .ais-RefinementList-item {
  margin-top: 0.1875rem; }

.ais-RefinementList-checkbox {
  margin-right: 0.3125rem; }

.ais-RefinementList-label:hover {
  cursor: pointer; }
  .ais-RefinementList-label:hover .ais-RefinementList-labelText {
    color: #047bb7;
    text-decoration: underline; }

.ais-RefinementList-showMore {
  border-radius: 0.1875rem; }
