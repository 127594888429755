@keyframes rotate {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }

.Spinner {
  display: inline-block;
  width: 4.6875rem;
  height: 4.6875rem;
  border: 0.625rem solid #d3e0e9;
  border-top-color: #047bb7;
  animation: rotate 750ms linear infinite;
  border-radius: 50%; }
