@keyframes toastEnter {
  from {
    opacity: 0;
    transform: translateX(100%); }
  to {
    opacity: 1;
    transform: translateX(0%); } }

.Toast {
  margin-top: 1.25rem;
  border-radius: 0.1875rem;
  background-color: #ffffff;
  color: #323333;
  border: 0.0625rem solid #d3e0e9;
  width: 18.75rem;
  padding: 0.625rem 2.5rem 0.625rem 1.25rem;
  position: relative;
  animation: toastEnter 1s; }

.Toast--success {
  background-color: #5cb85c;
  color: #ffffff;
  border-color: #5cb85c; }

.Toast--error {
  background-color: #d9534f;
  color: #ffffff;
  border-color: #d9534f; }

.Toast__remove {
  position: absolute;
  top: 0.4375rem;
  right: 0.4375rem;
  line-height: 1;
  opacity: 0.5; }

.Toast__remove:hover {
  cursor: pointer;
  opacity: 1; }
