.BlogPostAuthor {
  display: flex;
  align-items: center;
  line-height: 1; }

.BlogPostAuthor__media {
  flex: 0 0 3.125rem;
  margin-right: 0.625rem; }

.BlogPostAuthor__author {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.625rem; }

.BlogPostAuthor__date {
  font-size: 0.875rem; }
