.PageTitle {
  display: flex;
  margin-bottom: 2.5rem;
  align-items: center; }

.PageTitle__text {
  font-size: 2rem;
  margin: 0; }

.PageTitle__action {
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  top: 0.125rem;
  margin-left: 1.25rem; }
